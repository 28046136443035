'use strict'

import '../styles/app.scss'
import {EVENT_CONTENT_CHANGE} from "./events";



const initSelects = () => {
    $('select').each(function () {

        $(this).on('click', function (e) {
            let $this = $(this).closest('.js-select-group')
            if ($this.hasClass('opened')) {
                $this.removeClass('opened')
            } else {
                $this.addClass('opened')
            }
        });

        $(this).on('change', function (e) {
            $(this).closest('.js-select-group').removeClass('opened')

            $(this).blur()
        });

        $(this).on('blur', function (e) {
            $(this).closest('.js-select-group').removeClass('opened')
        });
    })
}


document.addEventListener(EVENT_CONTENT_CHANGE, function (e) {
    initTextareaCounter()
});

const updateTextAreaCounter = ($input) => {

    const counterOutput = (current, max) => {
        return `
        <div class="textarea-counter js-textarea-counter">
            ${current} / ${max}
        </div>
        `
    }

    const max = $input.attr("maxlength") ?? 200;
    const currentString = $input.val() ?? ''
    const current = currentString.length;
    const $counter = $input.siblings('.js-textarea-counter')

    if ($counter.length > 0) {
        $counter.remove()
    }

    if (current >= max) {
        $input.val(currentString.substring(0, max))
        $input.after(counterOutput($input.val().length, max))
        return false;
    }

    $input.after(counterOutput(current, max))
}

const initTextareaCounter = () => {

    $('textarea').each(function () {
        updateTextAreaCounter($(this))
    })

    $(document).on('input paste', 'textarea', function () {
        updateTextAreaCounter($(this))
    });
}

$(window).on('load', function () {

    initSelects()
    initTextareaCounter()

    const $header = $(".js-header");

    // If header isn't dark by default, add dark class, when scrolling down
    const updateHeader = () => {
        if ($(window).scrollTop() >= 1) {
            $header.addClass("js-header-scrolled");
        } else {
            $header.removeClass("js-header-scrolled");
        }
    }

    // Update header on scroll event
    document.addEventListener('scroll', function (event) {
        updateHeader()
    }, true);

    // Update header on page load
    updateHeader()

    // Remove class that allows default hover events
    $('.js-header').removeClass('js-not-loaded')
    initHeaderNavigation()

    $(document).on('click', '.js-mobile-menu-toggle', function () {
        let $header = $(this).closest('.js-header')
        $header.toggleClass('header--opened')
        $header.find('.js-mobile-navigation').slideToggle({
            duration: 300,
            easing: 'linear'
        })
        $('html').toggleClass('modal-open')
    });
});


// Adds click and hover events, to toggle child nav-menu visibility with an animation
const initHeaderNavigation = () => {
    $(document).on('click', '.js-header-menu-item', function () {
        let $this = $(this)
        let $header = $this.closest('.js-header')

        if ($this.hasClass('menu-opened')) {
            $this.removeClass('menu-opened')
            $this.addClass('menu-closed')
            setTimeout(function () {
                $this.removeClass('menu-closed')
            }, 300)
            $header.removeClass('active')
        } else {
            $this.removeClass('menu-closed').addClass('menu-opened')
            $header.addClass('active')
        }
    });

    if ($(window).width() <= 1200) {
        $(document).on('click', '.js-footer-menu-item', function () {
            let $this = $(this)
            $this.toggleClass('active')
            $this.next('.nav-menu').slideToggle(350)
        });
    }

    // Dont bind hover elements on mobile
    if ($(window).width() > 1200) {

        $(".js-header-menu-item")
            .mouseover(function () {
                let $this = $(this)

                let $header = $this.closest('.js-header')
                let $openedMenus = $header.find('.menu-opened')

                $openedMenus.removeClass('menu-opened').addClass('menu-closed')

                setTimeout(function () {
                    $openedMenus.removeClass('menu-closed')
                }, 300)

                $this.removeClass('menu-closed').addClass('menu-opened')
                $header.addClass('active')

            })
            .mouseout(function () {
                let $this = $(this)

                if ($this.parents('.menu-opened').length === 0) {
                    let $header = $this.closest('.js-header')
                    $header.find('.menu-opened').removeClass('menu-closed')
                }
            });

        $(".js-header").hover(
            function () {
            }, function () {
                let $this = $(this)
                $this.find('.menu-opened').removeClass('menu-opened')
                $this.find('.menu-closed').removeClass('menu-closed')
                $this.removeClass('active')
            }
        );
    }
}

